import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import gffReducer, {defaultGff} from './gffManager/gffReducer'
import hardwareReducer, {defaultHardware} from './hardwareManager/hardwareReducer'
import productReducer, {defaultProduts} from './productManager/productReducer'
import scoutReducer, {defaultScout} from './scoutManager/scoutReducer'
import systemReducer, {defaultSystemState} from './system/systemReducer'
import workOrderReducer, {defaultWorkOrder} from './workorder/workOrderReducer'
import auth0Reducer from './auth0/auth0Reducer'
import liveJobFeatureFlagReducer, { liveJobFeatureFlagState } from './workorder/liveJobFeatureFlag/liveJobFeatureFlagReducer'

const rootReducer = combineReducers({
    gff: gffReducer,
    hardware: hardwareReducer,
    product: productReducer,
    scout: scoutReducer,
    system: systemReducer,
    workOrder: workOrderReducer,
    auth0: auth0Reducer,
    liveJobFeatureFlag: liveJobFeatureFlagReducer,

    routing: routerReducer,
})

// create an object for the default data
export const defaultState = {
    gff: defaultGff,
    hardware: defaultHardware,
    product: defaultProduts,
    scout: defaultScout,
    system: defaultSystemState,
    workOrder: defaultWorkOrder,
    liveJobFeatureFlag: liveJobFeatureFlagState,
}

export default rootReducer

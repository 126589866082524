import './reportStyles.css'

import {DEFAULT_DISPLAY_VALUE, IMPERIAL, METRIC} from './applicationReportConstants'
import React, {Fragment, useEffect, useState} from 'react'

import {Button} from '@atd/rui-base.mui.button'
import {Checkbox} from '@atd/rui-base.mui.checkbox'
import {CircularProgress} from '@atd/rui-base.mui.circular-progress'
import FileFileDownload from 'material-ui/svg-icons/file/file-download'
import { FormControlLabel } from '@atd/rui-base.mui.all'
import ReportPage from './reportPage'
import axios from '../../lib/axios'
import { useParams } from 'react-router-dom'

const ApplicationReport = () => {
    const [allPageData, setAllPageData] = useState(null)

    const [isLoading, setIsLoading] = useState(true)
    const [jobDataNotFound, setJobDataNotFound] = useState(false)
    const [isCriticalError, setIsCriticalError] = useState(false)
    const [hideNullValues, setHideNullValues] = useState(true)
    const [jobName, setJobName] = useState('')

    const [uomPreference, setUomPreference] = useState(IMPERIAL)

    const urlParams = useParams()
    const workorderGuid = urlParams.guid || null


    useEffect(() => {
        if (!workorderGuid) {
            setIsCriticalError(true)
        }

        async function getConsolidatedApplicationReport() {
            const offsetInHoursAndMinutes = `${(new Date().getTimezoneOffset() / 60) * -1}:${(new Date().getTimezoneOffset() % 60)}:00`

            try {
                const response = await axios.get(`/jobs/net-api/consolidatedapplicationreport/${workorderGuid}?offset=${offsetInHoursAndMinutes}`)
                handleConsolidatedApplicationReportResponse(response.data)
            }
            catch (e) {
                if (e.response && e.response.status === 404) {
                    setJobDataNotFound(true)
                }
                else {
                    setIsCriticalError(true)
                }
            }
            setIsLoading(false)
        }

        getConsolidatedApplicationReport()
    }, [])

    function toggleUnits(value) {
        if (uomPreference === value) {
            return
        }

        if (uomPreference === METRIC) {
            setUomPreference(IMPERIAL)
        }
        else {
            setUomPreference(METRIC)
        }
    }

    function toggleHideNullValues() {
        setHideNullValues(!hideNullValues)
    }

    async function fetchShapefile() {
        const response = await axios.get('/jobs/net-api/consolidatedapplicationreport/' + workorderGuid + '/shapefile', { responseType: 'blob' })
        const blob = new Blob([response.data], { type: 'application/zip; charset=UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = jobName + '-shapefile.zip'
        link.click()
    }

    return (
        <div>
            {isLoading &&
            <div id="loading-container">
                <CircularProgress sx={{marginLeft: '50%'}}/>
            </div>
            }

            {(!isLoading && jobDataNotFound) &&
            <h2 className="report-status-text">No job data found</h2>
            }

            {(!isLoading && isCriticalError) &&
            <h2 className="report-status-text">An error occurred while loading the application report</h2>
            }

            {(!isLoading && !jobDataNotFound && !isCriticalError) &&
            <Fragment>
                <div className="hide-print report-right-aligned report-buttons">
                    <FormControlLabel label="Hide Missing Data" control={<Checkbox checked={hideNullValues} onChange={toggleHideNullValues} />} />
                    <Button sx={{margin: '5px'}} variant={uomPreference === IMPERIAL ? 'contained' : 'outlined'} onClick={() => toggleUnits(IMPERIAL)}>
                        Imperial
                    </Button>
                    <Button sx={{margin: '5px'}} variant={uomPreference === METRIC ? 'contained' : 'outlined'} onClick={() => toggleUnits(METRIC)}>
                        Metric
                    </Button>
                    <Button sx={{margin: '5px'}} variant="outlined" onClick={() => fetchShapefile()}>
                        <FileFileDownload /> Shapefile
                    </Button>
                    <Button sx={{margin: '5px'}} variant="outlined" onClick={() => window.print()}>
                        <FileFileDownload /> Print/PDF
                    </Button>
                </div>
                <ReportPage
                    allPageData={allPageData}
                    uomPreference={uomPreference}
                    hideNullValues={hideNullValues}
                />
                <div className="report-footer-webview">
                    <div className="slingshot-copyright">Copyright {new Date().getFullYear()} Slingshot&reg; All Rights Reserved</div>
                    <img className="powered-by-raven-img" src={`${process.env.PUBLIC_URL}/images/Powered_By_Raven.png`} alt="Powered By Raven"/>
                </div>
            </Fragment>
            }
        </div>
    )

    function handleConsolidatedApplicationReportResponse(apiData) {
        mapApiData(apiData)

        if (apiData.userPreferredUom.toLowerCase() === METRIC) {
            setUomPreference(METRIC)
        }
        else if (apiData.userPreferredUom.toLowerCase() === IMPERIAL) {
            setUomPreference(IMPERIAL)
        }
        else {
            setUomPreference(IMPERIAL)
        }
        document.title = `Raven Slingshot-${apiData.jobName}-${apiData.workOrderNumber}-Application Report`
        setJobName(apiData.jobName || apiData.workOrderNumber)
    }

    function mapApiData(apiData) {
        mapSessionData()

        function mapSessionData() {
            const products = []
            apiData.coverageMaps.forEach((element) => {
                products.push(element.product.name)
            })

            apiData.sessions.sort((a, b) => new Date(a.startTime) - new Date(b.startTime))

            const sessionIndexes = {}
            apiData.sessions.forEach((element, index) => {
                if (sessionIndexes[element.product.name]) {
                    sessionIndexes[element.product.name].push(index)
                }
                else {
                    sessionIndexes[element.product.name] = [index]
                }
            })

            const doneProducts = []
            const allPageData = []
            products.forEach((product, index) => {
                if (!doneProducts.includes(product)) {
                    const productData = {
                        headerInfo: headerData(apiData, product),
                        subheaderInfo: subheaderData(apiData, sessionIndexes[product]),
                        fieldInformation: fieldInformationData(apiData),
                        mapData: mapData(apiData, sessionIndexes[product], index),
                        applicationDetail: appDetailDataBigScreen(apiData, sessionIndexes[product]),
                        applicationDetailSmallScreen: appDetailDataSmallScreen(apiData, sessionIndexes[product]),
                        productInfo: productInfoData(apiData, sessionIndexes[product]),
                        ingredientInfo: ingredientData(apiData, index),
                    }
                    doneProducts.push(product)
                    allPageData.push(productData)
                }
            })
            setAllPageData(allPageData)
        }

        function headerData(apiData, product) {
            const headerInfo = {
                name: apiData.company.companyDisplayName || '',
                addressLine1: apiData.company.addressLine1 || '',
                addressLine2: apiData.company.addressLine2 || DEFAULT_DISPLAY_VALUE,
                addressLine3: apiData.company.addressLine3 || DEFAULT_DISPLAY_VALUE,
                phone: apiData.company.contactNumber || '',
                email: apiData.company.emailAddress || '',
                subtitle: product || DEFAULT_DISPLAY_VALUE,
                workorder: {
                    woNumber: apiData.workOrderNumber || DEFAULT_DISPLAY_VALUE,
                    woName: apiData.jobName || DEFAULT_DISPLAY_VALUE,
                },
            }

            return headerInfo
        }

        function subheaderData(apiData, sessionIndexes) {
            let completedArea = 0
            let totalVolume = 0
            let totalWeight = 0
            for (let i = 0; i < sessionIndexes.length; i++) {
                completedArea += apiData.sessions[sessionIndexes[i]].totalArea
                totalVolume += apiData.sessions[sessionIndexes[i]].product.totalVolume
                totalWeight += apiData.sessions[sessionIndexes[i]].product.totalWeight
            }

            let appVol = 0
            let applicationVolumeWeight = null
            if (totalVolume && completedArea) {
                appVol = totalVolume / completedArea
                applicationVolumeWeight = {
                    item: 'Avg. App Rate',
                    value: appVol || DEFAULT_DISPLAY_VALUE,
                    isUOM: true,
                    metricUnits: 'L/ha',
                    imperialUnits: 'gal/ac',
                    baseUnits: 'L/m2',
                    precision: 2,
                }
            }

            if (totalWeight && completedArea) {
                appVol = totalWeight / completedArea
                applicationVolumeWeight = {
                    item: 'Avg. App Rate',
                    value: appVol || DEFAULT_DISPLAY_VALUE,
                    isUOM: true,
                    metricUnits: 'kg/ha',
                    imperialUnits: 'lb/ac',
                    baseUnits: 'kg/m2',
                    precision: 2,
                }
            }

            // NOTE: array order affects order on page
            const subheaderInfo = []
            subheaderInfo.push({
                item: 'Application Type',
                value: (apiData.sessions[sessionIndexes[0]].product.productType || DEFAULT_DISPLAY_VALUE).replace('_', ' '),
            })
            subheaderInfo.push({
                item: 'Field Area',
                value: Number(apiData.field.area) || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'ha',
                imperialUnits: 'ac',
                precision: 2,
            })
            if (applicationVolumeWeight) {
                subheaderInfo.push(applicationVolumeWeight)
            }
            subheaderInfo.push({
                item: 'Completed Area',
                value: completedArea || DEFAULT_DISPLAY_VALUE,
                isUOM: true,
                metricUnits: 'ha',
                imperialUnits: 'ac',
                precision: 2,
            })

            return subheaderInfo
        }

        function fieldInformationData(apiData) {
            let countyState = DEFAULT_DISPLAY_VALUE
            if (apiData.field.county && apiData.field.state) {
                countyState = apiData.field.county + ' ' + apiData.field.state
            }
            else if (!apiData.field.county && apiData.field.state) {
                countyState = apiData.field.state
            }
            else if (apiData.field.county && !apiData.field.state) {
                countyState = apiData.field.county
            }

            const fieldInformation = {
                customer: apiData.grower.name || 'No Customer',
                office: apiData.grower.telephone1 || DEFAULT_DISPLAY_VALUE,
                address: apiData.grower.address || DEFAULT_DISPLAY_VALUE,
                cell: apiData.grower.telephone2 || DEFAULT_DISPLAY_VALUE,
                email: apiData.grower.email || DEFAULT_DISPLAY_VALUE,
                farm: apiData.farm.name || 'No Farm',
                field: apiData.field.name || 'No Field',
                township: apiData.field.township || DEFAULT_DISPLAY_VALUE,
                countyState: countyState || DEFAULT_DISPLAY_VALUE,
                section: apiData.field.section || DEFAULT_DISPLAY_VALUE,
            }

            return fieldInformation
        }

        function mapData(apiData, sessionIndexes, coverageMapIndex) {
            const mapData = {
                features: apiData.coverageMaps[coverageMapIndex].geoFeatures,
                isLiquid: (apiData.sessions[sessionIndexes[0]].product.liquid === '1'),
            }

            return mapData
        }

        function appDetailDataBigScreen(apiData, sessionIndexes) {
            return splitApplicationDetail(5, apiData, sessionIndexes)
        }

        function appDetailDataSmallScreen(apiData, sessionIndexes) {
            return splitApplicationDetail(2, apiData, sessionIndexes)
        }

        function appDetailData(apiData, sessionIndexes) {
            const colNames = []
            const completionDate = []
            const startTime = []
            const stopTime = []
            const operator = []
            const license = []
            const completedArea = []
            const totalVolume = []
            const totalWeight = []
            const equipment = []
            const groundSpeed = []
            const boomHeight = []
            const tipTypeTipSize = []
            const tipPressure = []
            const fieldConditions = []

            for (let i = 0; i < sessionIndexes.length; i++) {
                const opId = appDetailOperator(apiData, sessionIndexes, i)
                const notes = appDetailNotes(apiData, sessionIndexes, i)
                const tipTypeSize = (apiData.sessions[sessionIndexes[i]].equipments[0].tipType || DEFAULT_DISPLAY_VALUE) + ' / ' + (apiData.sessions[sessionIndexes[i]].equipments[0].tipSize || DEFAULT_DISPLAY_VALUE)
                const groundSpeedProps = {isUOM: true, uomPreference: uomPreference, imperialUnits: 'mph', metricUnits: 'km/h', baseUnits: 'm/sec'}

                colNames.push(i + 1)
                completionDate.push({value: getFormattedDateString(convertToDate(apiData.sessions[sessionIndexes[i]].startTime)) || DEFAULT_DISPLAY_VALUE})
                startTime.push({value: getFormattedDateTimeString(convertToDate(apiData.sessions[sessionIndexes[i]].startTime)) || DEFAULT_DISPLAY_VALUE})
                stopTime.push({value: getFormattedDateTimeString(convertToDate(apiData.sessions[sessionIndexes[i]].stopTime)) || DEFAULT_DISPLAY_VALUE})
                completedArea.push({value: Number(apiData.sessions[sessionIndexes[i]].totalArea) || DEFAULT_DISPLAY_VALUE, isUOM: true, uomPreference: uomPreference, metricUnits: 'ha', imperialUnits: 'ac'})
                totalVolume.push({value: Number(apiData.sessions[sessionIndexes[i]].product.totalVolume) || 0, isUOM: true, uomPreference: uomPreference, metricUnits: 'L', imperialUnits: 'gal', baseUnits: 'L'})
                totalWeight.push({value: Number(apiData.sessions[sessionIndexes[i]].product.totalWeight) || 0, isUOM: true, uomPreference: uomPreference, metricUnits: 'kg', imperialUnits: 'lb', baseUnits: 'kg'})
                boomHeight.push({value: Number(apiData.sessions[sessionIndexes[i]].equipments[0].height) || DEFAULT_DISPLAY_VALUE, isUOM: true, uomPreference: uomPreference, imperialUnits: 'ft', metricUnits: 'm', baseUnits: 'm'})
                tipTypeTipSize.push({value: tipTypeSize})
                tipPressure.push({value: apiData.sessions[sessionIndexes[i]].equipments[0].tipPressure || DEFAULT_DISPLAY_VALUE})
                fieldConditions.push({value: notes || DEFAULT_DISPLAY_VALUE})

                if (apiData.operators.length > 0) {
                    operator.push({value: apiData.operators[opId].name || DEFAULT_DISPLAY_VALUE})
                    license.push({value: apiData.operators[opId].licenseNumber || DEFAULT_DISPLAY_VALUE})
                    equipment.push({value: apiData.operators[opId].systemName || DEFAULT_DISPLAY_VALUE})
                    groundSpeed.push({...{value: Number(apiData.operators[opId].averageApplicationSpeed) || DEFAULT_DISPLAY_VALUE}, ...groundSpeedProps})
                }
                else {
                    operator.push({value: DEFAULT_DISPLAY_VALUE})
                    license.push({value: DEFAULT_DISPLAY_VALUE})
                    equipment.push({value: DEFAULT_DISPLAY_VALUE})
                    groundSpeed.push({...{value: DEFAULT_DISPLAY_VALUE}, ...groundSpeedProps})
                }
            }

            const applicationDetail = {
                tableTitle: 'Application Detail',
                columnNames: colNames,
                completionDate: completionDate,
                startTime: startTime,
                stopTime: stopTime,
                operator: operator,
                license: license,
                completedArea: completedArea,
                totalVolume: totalVolume,
                totalWeight: totalWeight,
                equipment: equipment,
                groundSpeed: groundSpeed,
                boomHeight: boomHeight,
                tipTypeTipSize: tipTypeTipSize,
                tipPressure: tipPressure,
                fieldConditions: fieldConditions,
            }

            return applicationDetail
        }

        function appDetailOperator(apiData, sessionIndexes, i) {
            let opId = 0 // default to operator 0
            for (let j = 0; j < apiData.operators.length; j++) {
                if (apiData.operators[j].fileIndex == apiData.sessions[sessionIndexes[i]].fileIndex && apiData.operators[j].id == apiData.sessions[sessionIndexes[i]].latestOperatorId) {
                    opId = j
                }
            }

            return opId
        }

        function appDetailNotes(apiData, sessionIndexes, i) {
            let notes = null
            if (apiData.notes.length >= apiData.sessions[sessionIndexes[i]].fileIndex + 1) {
                notes = apiData.notes[apiData.sessions[sessionIndexes[i]].fileIndex]
            }

            return notes
        }

        function splitApplicationDetail(tableColumns, apiData, sessionIndexes) {
            const applicationDetailWhole = appDetailData(apiData, sessionIndexes)

            let tableCount = 0
            const appDetailTableCols = applicationDetailWhole.completionDate.length

            tableCount = (appDetailTableCols - (appDetailTableCols % tableColumns)) / tableColumns
            if (appDetailTableCols % tableColumns) {
                tableCount += 1
            }

            const columnNames = []
            const completionDate = []
            const startTime = []
            const stopTime = []
            const operator = []
            const license = []
            const completedArea = []
            const totalVolume = []
            const totalWeight = []
            const equipment = []
            const groundSpeed = []
            const boomHeight = []
            const tipTypeTipSize = []
            const tipPressure = []
            const fieldConditions = []

            for (let i = 0; i < tableCount; i++) {
                columnNames.push(applicationDetailWhole.columnNames.splice(0, tableColumns))
                completionDate.push(applicationDetailWhole.completionDate.splice(0, tableColumns))
                startTime.push(applicationDetailWhole.startTime.splice(0, tableColumns))
                stopTime.push(applicationDetailWhole.stopTime.splice(0, tableColumns))
                operator.push(applicationDetailWhole.operator.splice(0, tableColumns))
                license.push(applicationDetailWhole.license.splice(0, tableColumns))
                completedArea.push(applicationDetailWhole.completedArea.splice(0, tableColumns))
                totalVolume.push(applicationDetailWhole.totalVolume.splice(0, tableColumns))
                totalWeight.push(applicationDetailWhole.totalWeight.splice(0, tableColumns))
                equipment.push(applicationDetailWhole.equipment.splice(0, tableColumns))
                groundSpeed.push(applicationDetailWhole.groundSpeed.splice(0, tableColumns))
                boomHeight.push(applicationDetailWhole.boomHeight.splice(0, tableColumns))
                tipTypeTipSize.push(applicationDetailWhole.tipTypeTipSize.splice(0, tableColumns))
                tipPressure.push(applicationDetailWhole.tipPressure.splice(0, tableColumns))
                fieldConditions.push(applicationDetailWhole.fieldConditions.splice(0, tableColumns))
            }
            const appDetailDataSplit = {
                tableTitle: applicationDetailWhole.tableTitle,
                columnNames: columnNames,
                completionDate: completionDate,
                startTime: startTime,
                stopTime: stopTime,
                operator: operator,
                license: license,
                completedArea: completedArea,
                totalVolume: totalVolume,
                totalWeight: totalWeight,
                equipment: equipment,
                groundSpeed: groundSpeed,
                boomHeight: boomHeight,
                tipTypeTipSize: tipTypeTipSize,
                tipPressure: tipPressure,
                fieldConditions: fieldConditions,
            }

            return appDetailDataSplit
        }

        function productInfoData(apiData, sessionIndexes) {
            let completedArea = 0
            let totalVolume = 0
            let totalWeight = 0
            for (let i = 0; i < sessionIndexes.length; i++) {
                completedArea += apiData.sessions[sessionIndexes[i]].totalArea
                totalVolume += apiData.sessions[sessionIndexes[i]].product.totalVolume
                totalWeight += apiData.sessions[sessionIndexes[i]].product.totalWeight
            }

            let appVol = 0
            let applicationRate = {}
            let totalProduct = {}
            if (totalVolume && completedArea) {
                appVol = totalVolume / completedArea
                applicationRate = {
                    item: 'App Rate',
                    value: appVol || DEFAULT_DISPLAY_VALUE,
                    isUOM: true,
                    metricUnits: 'L/ha',
                    imperialUnits: 'gal/ac',
                    baseUnits: 'L/m2',
                    precision: 2,
                }
                totalProduct = {
                    item: 'Total Product',
                    value: totalVolume || DEFAULT_DISPLAY_VALUE,
                    isUOM: true,
                    metricUnits: 'L',
                    imperialUnits: 'gal',
                    baseUnits: 'L',
                    precision: 2,
                }
            }

            if (totalWeight && completedArea) {
                appVol = totalWeight / completedArea
                applicationRate = {
                    item: 'App Rate',
                    value: appVol || DEFAULT_DISPLAY_VALUE,
                    isUOM: true,
                    metricUnits: 'kg/ha',
                    imperialUnits: 'lb/ac',
                    baseUnits: 'kg/m2',
                    precision: 2,
                }
                totalProduct = {
                    item: 'Total Product',
                    value: totalWeight || DEFAULT_DISPLAY_VALUE,
                    isUOM: true,
                    metricUnits: 'kg',
                    imperialUnits: 'lb',
                    baseUnits: 'kg',
                    precision: 2,
                }
            }

            const productData = {
                name: apiData.sessions[sessionIndexes[0]].product.name || DEFAULT_DISPLAY_VALUE,
                appRate: applicationRate,
                appliedArea: {item: 'Applied Area', value: completedArea || DEFAULT_DISPLAY_VALUE, isUOM: true, metricUnits: 'ha', imperialUnits: 'ac', precision: 2},
                totalProduct: totalProduct,
            }

            return productData
        }

        function ingredientData(apiData, coverageMapIndex) {
            const ingredients = []

            for (let j = 0; j < apiData.coverageMaps[coverageMapIndex].product.ingredients.length; j++) {
                const ingred = {
                    name: apiData.coverageMaps[coverageMapIndex].product.ingredients[j].name,
                    rate: Number(apiData.coverageMaps[coverageMapIndex].product.ingredients[j].rate),
                    company: apiData.coverageMaps[coverageMapIndex].product.ingredients[j].company,
                    quantity: Number(apiData.coverageMaps[coverageMapIndex].product.ingredients[j].quantity),
                    liquid: Number(apiData.coverageMaps[coverageMapIndex].product.ingredients[j].liquid),
                }

                ingredients.push(ingred)
            }

            const ingredientRows = []
            for (let i = 0; i < ingredients.length; i++) {
                const metricUnitsRate = ingredients[i].liquid ? 'L/ha' : 'kg/ha'
                const imperialUnitsRate = ingredients[i].liquid ? 'gal/ac' : 'lb/ac'
                const baseUnitsRate = ingredients[i].liquid ? 'L/m2' : 'kg/m2'

                const metricUnitsQuantity = ingredients[i].liquid ? 'L' : 'kg'
                const imperialUnitsQuantity = ingredients[i].liquid ? 'gal' : 'lb'
                const baseUnitsQuantity = ingredients[i].liquid ? 'L' : 'kg'

                ingredientRows.push({
                    rowName: ingredients[i].name || DEFAULT_DISPLAY_VALUE,
                    rate: {value: Number(ingredients[i].rate) || DEFAULT_DISPLAY_VALUE, isUOM: true, precision: 6, metricUnits: metricUnitsRate, imperialUnits: imperialUnitsRate, baseUnits: baseUnitsRate},
                    supplier: {value: ingredients[i].company || DEFAULT_DISPLAY_VALUE},
                    totalProduct: {value: Number(ingredients[i].quantity) || DEFAULT_DISPLAY_VALUE, isUOM: true, precision: 4, metricUnits: metricUnitsQuantity, imperialUnits: imperialUnitsQuantity, baseUnits: baseUnitsQuantity},
                })
            }

            const ingredientData = {
                ingredientRows: ingredientRows,
            }

            return ingredientData
        }
    }

    function convertToDate(dateString) {
        const newDate = new Date(dateString)
        if (newDate instanceof Date && !isNaN(newDate.getTime())) {
            return newDate
        }

        return null
    }

    function getFormattedDateTimeString(date) {
        if (!date) {
            return DEFAULT_DISPLAY_VALUE
        }

        return date.toLocaleString([], {
            hour12: false,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        })
    }

    function getFormattedDateString(date) {
        if (!date) {
            return DEFAULT_DISPLAY_VALUE
        }

        return date.toLocaleDateString()
    }
}

export default ApplicationReport

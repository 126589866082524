import {convertToBase, convertToDisplay} from '../lib/ddiUnits'
import {errorCatch, errorCheck} from '../lib/actionErrorHandler'

import axios from '../lib/axios'

export function selectField(field = {id: -1}) {

    return {
        type: 'SELECT_FIELD',
        field,
    }
}

export function addField(field) {
    return dispatch => {
        field.area = convertToBase(field.displayArea, field.areaDisplayUnit)

        return axios.post('/jobs/api/fields/', field)
            .then(response => response.data)
            .then(savedField => dispatch(selectField(savedField)))
            .then(() => dispatch(fetchFields()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function updateField(field) {
    return dispatch => {
        field.area = convertToBase(field.displayArea, field.areaDisplayUnit)

        return axios.put(`/jobs/api/fields/${field.id}`, field)
            .then(response => response.data)
            .then(() => dispatch(fetchFields()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function deleteField(fieldId) {
    return dispatch => {

        return axios.delete(`/jobs/api/fields/${fieldId}`)
            .then(() => dispatch(fetchFields()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function fetchFields() {
    return dispatch => {
        dispatch(requestFields())

        return axios.get('/jobs/api/fields?pageSize=500&page=1')
            .then(response => {
                dispatch(receiveFields(response.data.data))

                return response.data.pagination.next
            })
            .then(next => paginateFields(next, dispatch))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveFields([]))
            })
    }
}

function paginateFields(next, dispatch) {
    if (next) {
        return axios.get(`/jobs/api/fields?cursor=${next}`)
            .then(response => {
                dispatch(appendFields(response.data.data))

                return response.data.pagination.next
            })
            .then(next => paginateFields(next, dispatch))
    }

    return dispatch({type: 'DONE_FETCHING_FIELDS'})
}

function requestFields() {
    return {
        type: 'REQUEST_FIELDS',
    }
}

function receiveFields(fields) {
    if (!Array.isArray(fields)) { fields = [] }
    fields.forEach((field) => {
        field.displayArea = convertToDisplay(field.area, field.areaDisplayUnit).toFixed(2)
    })

    return {
        type: 'RECEIVE_FIELDS',
        fields: fields,
    }
}

function appendFields(fields) {
    if (!Array.isArray(fields)) { fields = [] }
    fields.forEach((field) => {
        field.displayArea = convertToDisplay(field.area, field.areaDisplayUnit).toFixed(2)
    })

    return {
        type: 'APPEND_FIELDS',
        fields: fields,
    }
}

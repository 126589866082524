import { actions } from './liveJobFeatureFlagActions'

export const liveJobFeatureFlagState = {
    isFetching: true,
    value: null,
}

export default function liveJobFeatureFlagReducer(state = liveJobFeatureFlagState, action) {
    switch (action.type) {
        case actions.LIVE_JOB_FF_FETCH_PENDING:
            return {
                ...state,
                isFetching: true,
            }
        case actions.LIVE_JOB_FF_FETCH_DONE:
            return {
                value: action.payload,
                isFetching: false,
            }
        default:
            return state
    }
}

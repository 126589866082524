export const actions = {
    LIVE_JOB_FF_FETCH_PENDING: 'LIVE_JOB_FF_FETCH_PENDING',
    LIVE_JOB_FF_FETCH_DONE: 'LIVE_JOB_FF_FETCH_DONE',
}

function fetchPending() {
    return {
        type: actions.LIVE_JOB_FF_FETCH_PENDING,
    }
}

function fetchDone(isEnabled) {
    return {
        type: actions.LIVE_JOB_FF_FETCH_DONE,
        payload: isEnabled,
    }
}

export function fetchliveJobFeatureFlag() {
    return dispatch => {
        dispatch(fetchPending())

        return fetch('/jobs/api/featureflag/live-job', {method: 'GET', credentials: 'same-origin'})
            .then(res => res.json())
            .then(res => {
                const isEnabled = res && res.access === 'allow_all'
                dispatch(fetchDone(isEnabled))
            })
            .catch(() => {
                dispatch(fetchDone(false))
            })
    }
}

// a reducer takes in two things:
// 1. the action (info about what happened)
// 2. a copy of the current state
import { combineReducers } from 'redux'
import update from 'immutability-helper'

function farms(state = [], action) {
    switch (action.type) {
        case 'RECEIVE_FARMS':
        case 'SET_FARMS':
            return update(state, {
                $set: action.farms,
            })
        default:
            return state
    }
}

function fields(state = [], action) {
    switch (action.type) {
        case 'RECEIVE_FIELDS':
        case 'SET_FIELDS':
            return update(state, {
                $set: action.fields,
            })
        case 'APPEND_FIELDS':
            return update(state, {
                $push: action.fields,
            })
        default:
            return state
    }
}

function growers(state = [], action) {
    switch (action.type) {
        case 'RECEIVE_GROWERS':
        case 'SET_GROWERS':
            return update(state, {
                $set: action.growers,
            })
        default:
            return state
    }
}

function isFetching(state = true, action) {
    switch (action.type) {
        case 'REQUEST_GROWERS':
            return update(state, { $set: true })
        case 'RECEIVE_GROWERS':
            return update(state, { $set: false })
        default:
            return state
    }
}

function selectedFarm(state = [], action) {
    switch (action.type) {
        case 'SELECT_FARM':
            return update(state, {
                $set: action.farm,
            })
        default:
            return state
    }
}

function selectedField(state = [], action) {
    switch (action.type) {
        case 'SELECT_FIELD':
            return update(state, {
                $set: action.field,
            })
        default:
            return state
    }
}

function selectedGrower(state = [], action) {
    switch (action.type) {
        case 'SELECT_GROWER':
            return update(state, {
                $set: action.grower,
            })
        default:
            return state
    }
}

export const defaultGff = {
    farms: [],
    fields: [],
    growers: [],
    isFetching: true,
    selectedFarm: {id: -1},
    selectedField: {id: -1},
    selectedGrower: {id: -1},
}
const gffReducer = combineReducers({
    farms,
    fields,
    growers,
    isFetching,
    selectedFarm,
    selectedField,
    selectedGrower,
})

export default gffReducer
